<template>
  <div class="manage-property-edit animatedBox">
    <div class="container fluid">
      <h1 class="p-2 mb-2">Edit Property</h1>

      <PropertyForm
        v-if="isDataLoaded"
        :form.sync="property"
        @submit="submitForm"
      ></PropertyForm>
    </div>
  </div>
</template>

<script>
import propertyModel from "@/models/property";
import PropertyForm from "@/modules/Property/components/PropertyForm";

export default {
  name: "manage-property-edit",
  components: {
    PropertyForm
  },
  mixins: [],
  computed: {},
  data() {
    return {
      isDataLoaded: false,

      property: {
        propertyOwner: {},
        propertyCommission: {},
        offerToPurchase: {},
        offerToRent: {},
        propertyTags: [],
        hashtag: "",
        remark: "",
        // latlng
        latitude: "",
        longitude: ""
      },
      propertyStatus: ""
    };
  },
  created: function () {},
  mounted: function () {
    this.initData();
  },
  beforeDestroy() {},
  methods: {
    async initData() {
      try {
        this.$store.commit("setIsLoading", true);
        await this.getProperty();
        this.checkPermission();

        this.isDataLoaded = true;
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
      }
    },

    checkPermission() {
      // Redirects if not own property
      if (this.$auth.user().id != this.property.agencyUser.user.id) {
        this.$router.push({ name: "ManageProperty" });
      }
    },

    validateDepositRates(value1, value2) {
      if (Number(value1) + Number(value2) > 10) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "OTP Total Deposit Limit",
          text:
            "Sum of first deposit and second deposit rate should be more than 10%"
        });
        return false;
      }
      return true;
    },

    // ============================== API Related ==============================
    async getProperty() {
      try {
        let data = await this.$store.dispatch(
          this.$route.query.role == "agency"
            ? "manageProperty/getAgencyProperty"
            : "manageProperty/getMyProperty",
          {
            id: this.$route.params.id
          }
        );

        this.property = propertyModel.getToEditResponse(data);
        this.propertyStatus = this.property.status;
      } catch (error) {
        throw error;
      }
    },

    async submitForm() {
      this.$store.commit("setIsLoading", true);
      try {
        await this.$store.dispatch("manageProperty/updateMyProperty", {
          id: this.$route.params.id,
          payload: propertyModel.postPayload(this.property)
        });
        this.$store.commit("setIsLoading", false);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Property has been updated successfully."
        });

        this.$router.push({ name: "ManageProperty" });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false);

        let errorMessage = null;
        if (error.response != null) {
          errorMessage = {
            user: this.$auth.user().id,
            api: error.response.config.url,
            message:
              typeof error.response.data != "string"
                ? JSON.stringify(error.response.data)
                : error.response.data
          };
        } else {
          errorMessage = {
            user: this.$auth.user().id,
            message: error
          };
        }

        this.$reportError(JSON.stringify(errorMessage));

        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to update property data. Please try again later."
        });

        throw error;
      }
    }
  }
};
</script>

<style lang="scss"></style>
